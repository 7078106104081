import React from 'react';
import { graphql } from 'gatsby';

import { Layout, SEO } from 'src/components/shared';
import { JobsLayout, LayoutCenter, JobForm } from 'src/components/jobs';

export default function Template({ data: { markdownRemark, background } }) {
	const { frontmatter } = markdownRemark;

	return (
		<Layout
			withLogo
			backgroundProps={{ fluid: background.childImageSharp.fluid }}
		>
			<SEO title={`Apply: ${frontmatter.position}`} />

			<JobsLayout variant="single">
				<LayoutCenter>
					<JobForm data={frontmatter}></JobForm>
				</LayoutCenter>
			</JobsLayout>
		</Layout>
	);
}

export const pageQuery = graphql`
	query($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			frontmatter {
				position
				showreel
			}
		}
		background: file(relativePath: { eq: "background_jobs.png" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
